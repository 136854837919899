import reportWebVitals from "./reportWebVitals";
import React, { StrictMode, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { ErrorBoundary } from "@highlight-run/react";
// import { H } from "highlight.run";
import Loading from "./component/ui/Loading";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          {/* <Suspense fallback={<Loading />}> */}
          <Suspense>
              <App />
          </Suspense>
        </Provider>
      </BrowserRouter>
    </StrictMode>
  </>
);

